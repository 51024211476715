import Price from '../Price';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { formatPercentageValue } from '/utils/format.js';
import { getEstimatedMoneyBack } from '/services/static/promotions/money-back';
import { useGlobalStore } from '/state/global-context';
import { useEffect, useState } from 'react';

const MoneyBackCartDetail = ({ processedCart }) => {
	const [moneyBackDetails, setMoneyBackDetails] = useState();
	const globalStore = useGlobalStore();
	useEffect(() => {
		const moneyBack = getEstimatedMoneyBack(processedCart.subTotal);
		setMoneyBackDetails(moneyBack);
	}, [processedCart.subTotal]);

	const currentPromotion = globalStore.getPromotionalConfig();

	if (!currentPromotion || currentPromotion.promotionType !== 'money-back' || !moneyBackDetails) {
		return null;
	}

	return (
		<div
			className="text-center py-2"
			style={{
				backgroundColor: currentPromotion.sitewideBanner.colorPrimary,
				color: currentPromotion.sitewideBanner.colorSecondary,
			}}>
			{moneyBackDetails.estimate == 0 && (
				<Typography>Right now: Get Money Back on your order</Typography>
			)}

			{moneyBackDetails.nextTierPriceDifference && moneyBackDetails.estimate !== 0 && (
				<Typography>
					You&apos;ve got {formatPercentageValue(moneyBackDetails.tier.moneyBack, false, true)}{' '}
					money back on this order
				</Typography>
			)}

			{moneyBackDetails.nextTierPriceDifference && (
				<Typography className="font-bold uppercase" component="p" variant="body">
					Add <Price price={moneyBackDetails.nextTierPriceDifference} showSymbol={true} /> to get{' '}
					{moneyBackDetails.nextTier && (
						<>{formatPercentageValue(moneyBackDetails.nextTier.moneyBack, false, true)}</>
					)}{' '}
					back
				</Typography>
			)}

			{/* Top Tier */}
			{!moneyBackDetails.nextTierPriceDifference && moneyBackDetails.tier && (
				<>
					<Typography component="p" variant="body">
						You&apos;re getting{' '}
						{formatPercentageValue(moneyBackDetails.tier.moneyBack, false, true)} Back
					</Typography>
					<Typography className="font-bold uppercase" component="p" variant="body">
						Add more to keep earning 50% back
					</Typography>
				</>
			)}
		</div>
	);
};

MoneyBackCartDetail.propTypes = {
	processedCart: PropTypes.object,
};

export default MoneyBackCartDetail;
